import { useMiddlewareStore } from '@/composables/useMiddlewareStore';
import routerConstants from '@/app/router/router.constants';
import { useUserStore } from '@/composables/useUserStore';
import { RouteLocation, RouteLocationNormalized } from 'vue-router';
import type { LocationQuery } from 'vue-router';
import { UserType } from '@/types';
import { useSignedLogin } from '@/composables/useCoreApi';
import { userStorageKey } from '@/composables/storageKeys';
export const getIsRouteAuthenticated = (route: RouteLocationNormalized) =>
  route.matched.some((record) => record.meta.authenticated);

export const getRouteInvite = (route: RouteLocationNormalized) =>
  route.params.inviteId as string;

const loginFromHash = (route: RouteLocation) => {
  const userStore = useUserStore();
  type LoginParams = {
    token: string;
    type: UserType;
    shopName: string;
  };
  const loginParams: LoginParams = Object.fromEntries(
    new URLSearchParams(window.location.hash.slice(1))
  ) as LoginParams;
  if (loginParams.token) {
    localStorage.removeItem('middleware-token');
    const {
      routes: { DIRECTOR, DIRECTOR_SETUP },
    } = routerConstants;
    //ts doesn't seem to understand the point of 'includes' so this makes the array more generic
    const directorRoutes: string[] = [DIRECTOR, DIRECTOR_SETUP];
    loginParams.type = directorRoutes.includes(route.name as string)
      ? 'director'
      : 'host-producer';
    userStore.setUser(loginParams);
  }
};
const loginWithSignature = async ({ sig, exp, id }: LocationQuery) => {
  const userStore = useUserStore();

  const signedLoginFetch = useSignedLogin(
    sig as string,
    exp as string,
    id as string
  );
  await signedLoginFetch.execute();
  localStorage.removeItem('middleware-token');
  if (signedLoginFetch.data.value) {
    userStore.setUser(signedLoginFetch.data.value?.data);
  }
};
export const getIsUserAuthenticated = async (route: RouteLocation) => {
  const userStore = useUserStore();
  //devs and qa often swap between shops, this helps mitigate shop mismatches between vdash/core and broadcast web
  userStore.getStoredUser();
  if (
    route.query.shopName &&
    userStore.user.shopName !== route.query.shopName
  ) {
    localStorage.removeItem(userStorageKey);
    userStore.setToken('');
  }
  if (route.query.sig && route.query.exp && route.query.id) {
    await loginWithSignature(route.query);
  }
  if (window.location.hash) {
    loginFromHash(route);
  }
  return userStore.isLoggedIn;
};
export const toLoginPage = (route: RouteLocationNormalized) => {
  return {
    name: routerConstants.routes.LOGIN,
    params: route.params,
    query: { redirect: route.name },
  };
};

export const toInvalidInvitePage = (route: RouteLocationNormalized) => {
  return {
    name: routerConstants.routes.ERROR.INVALID_INVITE,
    params: route.params,
  };
};

export const toErrorAuthenticatingPage = (route: RouteLocationNormalized) => ({
  name: routerConstants.routes.ERROR.INVITE_CHECK_ERROR,
  params: route.params,
  query: { inviteId: route.params.inviteId },
});

const handleGuestUser = async (
  route: RouteLocationNormalized,
  inviteId: string
) => {
  const userStore = useUserStore();
  const middlewareStore = useMiddlewareStore();
  const { authenticateGuestUser } = middlewareStore;
  try {
    await authenticateGuestUser(inviteId);
    userStore.setUser({
      type: 'guest-producer',
      shopName: middlewareStore.agoraChannel.shopName,
    });
    return;
  } catch (e) {
    console.error(e);
    if ((e as Response).status === 404) {
      return toInvalidInvitePage(route);
    } else {
      return toErrorAuthenticatingPage(route);
    }
  }
  //join channel via multihost library
};
const setAuthenticatedUserType = (route: RouteLocationNormalized) => {
  const userStore = useUserStore();
  for (const match of route.matched) {
    if (match.name === routerConstants.routes.BROADCAST.HOST) {
      userStore.setUser({ type: 'host-producer' });
    } else {
      userStore.setUser({ type: 'director' });
    }
  }
};
const handleAuthenticatedUser = async (route: RouteLocationNormalized) => {
  const isUserAuthenticated = await getIsUserAuthenticated(route);
  if (!isUserAuthenticated) {
    return toLoginPage(route);
  }
  setAuthenticatedUserType(route);
};
export const handleAuthenticatedRoute = (route: RouteLocationNormalized) => {
  const inviteId = getRouteInvite(route);
  if (inviteId) {
    return handleGuestUser(route, inviteId);
  } else {
    return handleAuthenticatedUser(route);
  }
};
