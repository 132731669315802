import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useCoreApi } from '@/composables/useCoreApi';
import { decodeJWT, isJWTExpired } from '@/services/decodeJWT';
import { MiddlewareService, useMultihostService } from '@commentsold/multihost';
import { AfterFetchContext } from '@vueuse/core';
import { useUserStore } from './useUserStore';

const tokenKey = 'middleware-token';

export const useMiddlewareStore = defineStore('mhcMiddlewareStore', () => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  const multihostService = useMultihostService();
  const { useGetMiddlewareToken, useStartRTMP } = useCoreApi();

  const middlewareToken = ref('');
  const middlewareTokenFetch = useGetMiddlewareToken({
    immediate: false,
    beforeFetch({ cancel }) {
      const token = localStorage.getItem(tokenKey);
      if (token && !isJWTExpired(token)) {
        setMiddlewareToken(token);
        middlewareTokenFetch.data.value = { token };
        cancel();
      }
    },
    afterFetch(context: AfterFetchContext<{ token: string }>) {
      if (context.data?.token) {
        setMiddlewareToken(context.data?.token);
        localStorage.setItem(tokenKey, context.data?.token);
      }
      return context;
    },
  });
  const setMiddlewareToken = (token: string) => {
    middlewareToken.value = token;
    try {
      const decodedToken = decodeJWT<{ shid: 'string' }>(token);
      userStore.setShopName(decodedToken.shid);
    } catch (e) {
      console.error('failed to get shop name from token');
    }
  };

  const agoraChannel = ref<{ id: string; name: string; shopName: string }>({
    id: '',
    name: '',
    shopName: '',
  });
  const agoraChannelId = computed<string>(() => agoraChannel.value.id);
  const rtmpURLFetch = useStartRTMP(
    {
      immediate: false,
    },
    agoraChannelId
  );
  const setAgoraChannelId = (id: string) => (agoraChannel.value.id = id);
  const getAgoraChannel = async (channelId = '') => {
    let channel: Awaited<ReturnType<typeof MiddlewareService.getChannel>>;
    if (channelId) {
      channel = await MiddlewareService.getChannel(channelId);
    } else {
      const channels = await MiddlewareService.getChannels();
      channel = channels[0];
    }
    if (channel) {
      agoraChannel.value.id = channel.id;
      agoraChannel.value.name = channel.name;
      agoraChannel.value.shopName = channel.shop;
    }
  };
  const getOrCreateAgoraChannel = async () => {
    const channel: Awaited<
      ReturnType<typeof MiddlewareService.getOrCreateChannel>
    > = await MiddlewareService.getOrCreateChannel();
    if (channel) {
      agoraChannel.value.id = channel.id;
      agoraChannel.value.name = channel.name;
      agoraChannel.value.shopName = channel.shop;
    }
  };
  const joinChannel = async () => {
    if (user.value.type !== 'guest-producer') {
      await multihostService.joinChannel(
        user.value.type,
        agoraChannel.value.id
      );
    } else {
      await multihostService.joinChannel(
        user.value.type,
        agoraChannel.value.id,
        inviteId.value
      );
    }
  };
  const closeChannel = async () => {
    await multihostService.closeChannel();
  };
  /**
   * We get the guest agora auth to check if they're valid users. We can store it here so we don't have to make another auth call in the library
   */
  const guestAgoraAuth = ref({
    rtc_token: '',
    rtm_token: '',
    uid: 0,
  });
  const setGuestAgoraAuth = (auth: typeof guestAgoraAuth.value) =>
    (guestAgoraAuth.value = auth);
  const inviteId = ref('');
  const setInviteId = (newId: string) => (inviteId.value = newId);
  /**
   * Exchanges a guest invite token for agora authentication tokens. Throws on error.
   * @param inviteId An invite id created by the agora middleware service
   * @returns {rtc_token, rtm_token, uid}
   */
  const authenticateGuestUser = async (inviteId: string) => {
    const auth = await MiddlewareService.exchangeInviteForTokens(inviteId);
    setInviteId(inviteId);
    setGuestAgoraAuth({
      rtc_token: auth.rtc_token,
      rtm_token: auth.rtm_token,
      uid: auth.agora_uid as number,
    });
    setAgoraChannelId(auth.channel_id);
    await getAgoraChannel(auth.channel_id);
    return auth;
  };
  return {
    middlewareToken,
    middlewareTokenFetch,
    setMiddlewareToken,
    getAgoraChannel,
    getOrCreateAgoraChannel,
    authenticateGuestUser,
    guestAgoraAuth,
    inviteId,
    agoraChannel,
    setAgoraChannelId,
    rtmpURLFetch,
    joinChannel,
    closeChannel,
  };
});
