export const decodeJWT = <T>(token: string) => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid token format');
  }
  const payload = JSON.parse(atob(parts[1]));
  return payload as T;
};

export const isJWTExpired = (token: string) => {
  const { exp } = decodeJWT<{ exp: number }>(token);
  return Date.now() > exp * 1000;
};
