<template>
  <main
    class="tw-flex-1 tw-max-h-full tw-flex tw-items-center tw-justify-center tw-bg-black"
  >
    <RouterView />
  </main>
</template>

<script setup lang="ts">
import { RouterView, useRoute, useRouter } from 'vue-router';
import { useMultihostService } from '@commentsold/multihost';
import routerConstants from '../router/router.constants';
import { useBroadcastSettingsStore } from '@/composables/useBroadcastSettingsStore';
import { useAppStore } from '@/composables';
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';

const route = useRoute();
const router = useRouter();
const multihostService = useMultihostService();
const appStore = useAppStore();
const settingsStore = useBroadcastSettingsStore();
const { getBroadcastSettings } = settingsStore;
const { disableAgoraCongestionControl } = storeToRefs(settingsStore);
const isGuest = route.name === routerConstants.routes.BROADCAST.GUEST;

const configureFeatureFlags = async () => {
  if (disableAgoraCongestionControl.value) {
    multihostService.disableAgoraCongestionControl();
  }
};

onMounted(async () => {
  await getBroadcastSettings(isGuest);
  await configureFeatureFlags();

  await multihostService.initialize();
  appStore.multihostSeviceIsIntialized = true;
});

multihostService.onChannelClosed(() => {
  const { HOST, GUEST } = routerConstants.routes.LIVE_ENDED;
  const liveEndedRoute = isGuest ? GUEST : HOST;
  router.push({ name: liveEndedRoute });
});
</script>
