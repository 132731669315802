import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { BitrateOption, ShopBroadcastSettings } from '@/types';
import { defaultShopBroadcastSettings } from '@/utils/defaultSettings';
import { FeatureFlagType, defaultFeatureFlags } from '@/utils/featureFlags';
import { useCoreApi } from './useCoreApi';
import { useMediaBitrate } from '@commentsold/multihost';
import { useUserStore } from './useUserStore';
import { useMiddlewareStore } from './useMiddlewareStore';

export const useBroadcastSettingsStore = defineStore('bwSettingsStore', () => {
  const {
    useGetBroadcastSettings,
    useGetGuestBroadcastSettings,
    useUpdateBroadcastSettings,
  } = useCoreApi();
  const mhMediaBitrateStore = useMediaBitrate();
  const shopBroadcastSettings = ref<ShopBroadcastSettings>(
    defaultShopBroadcastSettings
  );
  const setShopBroadcastSettings = (settings: ShopBroadcastSettings) => {
    shopBroadcastSettings.value = settings;
  };
  const shopFeatureFlags =
    ref<Record<FeatureFlagType, boolean>>(defaultFeatureFlags);
  const setShopFeatureFlags = (flags: Record<FeatureFlagType, boolean>) => {
    shopFeatureFlags.value = flags;
  };
  const showNetworkWarnings = computed(
    () => shopFeatureFlags.value['feature.multihost.show-network-warnings']
  );
  const disableAgoraCongestionControl = computed(
    () =>
      shopFeatureFlags.value[
        'feature.multihost.disable-agora-congestion-control'
      ]
  );
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);
  const middlewareStore = useMiddlewareStore();
  const { agoraChannel } = storeToRefs(middlewareStore);

  const shopName = computed(() => {
    if (user.value.shopName) {
      return user.value.shopName;
    } else if (agoraChannel.value.shopName) {
      return agoraChannel.value.shopName;
    } else {
      return '';
    }
  });
  const {
    execute: executeGetBroadcastSettings,
    data: broadcastSettings,
    isFetching: isFetchingGetBroadcastSettings,
  } = useGetBroadcastSettings(
    {
      immediate: false,
      onFetchError: (ctx) => {
        console.error([
          '[@commentsold/multihost-components] Failed to fetch broadcast settings',
          ctx.error,
        ]);
        ctx.data = defaultShopBroadcastSettings;
        return ctx;
      },
    },
    Object.keys(defaultFeatureFlags)
  );
  const {
    execute: executeGetGuestBroadcastSettings,
    data: guestBroadcastSettings,
    isFetching: isFetchingGetGuestBroadcastSettings,
  } = useGetGuestBroadcastSettings(
    {
      immediate: false,
      onFetchError: (ctx) => {
        console.error([
          '[@commentsold/multihost-components] Failed to fetch guest broadcast settings',
          ctx.error,
        ]);
        ctx.data = defaultShopBroadcastSettings;
        return ctx;
      },
    },
    Object.keys(defaultFeatureFlags),
    shopName
  );

  const getBroadcastSettings = async (isGuest: boolean) => {
    if (isGuest) {
      await executeGetGuestBroadcastSettings();
    } else {
      await executeGetBroadcastSettings();
    }
    const userBroadcastSettings = isGuest
      ? guestBroadcastSettings
      : broadcastSettings;
    if (userBroadcastSettings.value) {
      const shopSettings = userBroadcastSettings.value.settings;
      const flags = userBroadcastSettings.value.flags;
      if (shopSettings) {
        setShopBroadcastSettings({
          ...shopBroadcastSettings.value,
          ...shopSettings,
        });
        if (!isGuest && shopSettings.host) {
          mhMediaBitrateStore.setVideoBitrateKbps(
            shopSettings.host.videoBitrate
          );
          mhMediaBitrateStore.setAudioBitrateKbps(
            shopSettings.host.audioBitrate
          );
        } else {
          mhMediaBitrateStore.setVideoBitrateKbps(
            shopSettings.guest.defaultVideoBitrate
          );
          mhMediaBitrateStore.setAudioBitrateKbps(
            shopSettings.guest.defaultAudioBitrate
          );
        }
      }
      if (flags) {
        setShopFeatureFlags(flags);
      }
    }
  };

  const {
    execute: executeUpdateBroadcastSettings,
    isFetching: isFetchingUpdateBroadcastSettings,
  } = useUpdateBroadcastSettings(
    {
      immediate: false,
      onFetchError: (ctx) => {
        console.error([
          '[@commentsold/multihost-components] Failed to update broadcast settings',
          ctx.error,
        ]);
        ctx.data = defaultShopBroadcastSettings;
        return ctx;
      },
    },
    shopBroadcastSettings
  );

  const isFetchingSettings = computed(() => {
    return (
      isFetchingGetGuestBroadcastSettings.value ||
      isFetchingGetBroadcastSettings.value ||
      isFetchingUpdateBroadcastSettings.value
    );
  });

  const updateHostDefaultVideoBitrate = async (bitrate: BitrateOption) => {
    if (shopBroadcastSettings.value.host) {
      setShopBroadcastSettings({
        ...shopBroadcastSettings.value,
        host: {
          ...shopBroadcastSettings.value.host,
          videoBitrate: bitrate,
        },
      });
      return executeUpdateBroadcastSettings();
    } else {
      console.error(
        '[@commentsold/multihost-components] Failed to update host default video bitrate'
      );
    }
  };

  const updateHostDefaultAudioBitrate = async (bitrate: number) => {
    if (shopBroadcastSettings.value.host) {
      setShopBroadcastSettings({
        ...shopBroadcastSettings.value,
        host: {
          ...shopBroadcastSettings.value.host,
          audioBitrate: bitrate,
        },
      });
      return executeUpdateBroadcastSettings();
    } else {
      console.error(
        '[@commentsold/multihost-components] Failed to update host default audio bitrate'
      );
    }
  };

  return {
    shopBroadcastSettings,
    setShopBroadcastSettings,
    shopFeatureFlags,
    setShopFeatureFlags,
    showNetworkWarnings,
    getBroadcastSettings,
    isFetchingSettings,
    updateHostDefaultVideoBitrate,
    updateHostDefaultAudioBitrate,
    disableAgoraCongestionControl,
  };
});
