import { FeatureFlagType } from './utils/featureFlags';

export enum Layout {
  FullScreen_Host = 'FullScreen_Host',
  FullScreen_Guest = 'FullScreen_Guest',
  PictureInPicture_HostFocus = 'PictureInPicture_HostFocus',
  PictureInPicture_GuestFocus = 'PictureInPicture_GuestFocus',
  Split_HostOnTop = 'Split_HostOnTop',
  Split_GuestOnTop = 'Split_GuestOnTop',
  PictureInPicture_BroadcastView = 'PictureInPicture_BroadcastView',
}

export type StreamStatus = 'live' | 'offline' | 'streaming';

export type Origin = 'videeo' | 'commentsold';

export type UserType = 'host-producer' | 'guest-producer' | 'director';

export type User = {
  id: number | string;
  name: string;
  email: string;
  shopName: string;
  token: string;
  type: UserType;
};

export type UserCredentials = {
  token: string;
};

export type StreamProduct = {
  product_id: number;
  product_name: string;
  identifier: string;
  price_label: string;
  inventory: StreamInventoryProduct[];
  thumbnail: string;
  attr1DisplayName?: string;
  attr2DisplayName?: string;
  attr3DisplayName?: string;
};

export type StreamInventoryProduct = {
  position: number;
  color: string;
  size: string;
  quantity: number;
  allow_oversell: boolean;
};

export type StreamInventoryShopifyProduct = StreamInventoryProduct & {
  attr1: string;
  attr2: string;
  attr3: string;
};

export type StreamInventoryProductAttributeValueDisplayInfo = {
  value: string;
  quantity: number;
  allowOversell: boolean;
};

export type BitrateOption = 2000 | 1200 | 1300 | 1620 | 2500 | 4999;

export type ShopBroadcastSettings = {
  host: null | {
    videoBitrate: BitrateOption;
    audioBitrate: number;
  };
  guest: {
    defaultVideoBitrate: BitrateOption;
    defaultAudioBitrate: number;
  };
};

export type BroadcastSettingsResponse = {
  settings: ShopBroadcastSettings;
  flags: Record<FeatureFlagType, boolean>;
};
