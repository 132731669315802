import { computed, ref, watch } from 'vue';
import { StreamStatus } from '@/types';
import { defineStore, storeToRefs } from 'pinia';
import { useMultihostService } from '@commentsold/multihost';
import { useCoreApi } from './useCoreApi';
import { useUserStore } from './useUserStore';
import { formatSecondsToDuration } from '@/utils/formatSecondsToDuration';
import { useMiddlewareStore } from './useMiddlewareStore';
import { useAppStore } from './useAppStore';

let streamInfoPollingInterval = 10000;
export const setStreamInfoPollingInterval = (interval: number) => {
  streamInfoPollingInterval = interval;
};

const viewerCountFormat = Intl.NumberFormat('en', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const useStreamStore = defineStore('mhcStreamStore', () => {
  const { useGetVideeoStreamInfo, useGetCoreStreamInfo } = useCoreApi();

  const middlewareStore = useMiddlewareStore();
  const multihostService = useMultihostService();
  const appStore = useAppStore();

  const { agoraChannel } = storeToRefs(middlewareStore);
  const { RTMEvents } = multihostService;
  const { localUser, hasJoinedChannel } = storeToRefs(multihostService);
  const { origin } = storeToRefs(appStore);

  const { user } = storeToRefs(useUserStore());
  const streamShopName = computed(() => {
    if (user.value.shopName) {
      return user.value.shopName;
    } else if (agoraChannel.value.shopName) {
      return agoraChannel.value.shopName;
    } else {
      return '';
    }
  });
  const streamInfoLoaded = ref<boolean>(false);
  const {
    execute: getVideeoStreamInfo,
    data: videeoStreamInfoData,
    isFetching: videeoStreamInfoIsFetching,
    abort: abortVideeoStreamInfoFetch,
    canAbort: canAbortVideeoStreamInfoFetch,
  } = useGetVideeoStreamInfo({ immediate: false }, streamShopName);
  const {
    execute: getCoreStreamInfo,
    data: coreStreamInfoData,
    isFetching: coreStreamInfoIsFetching,
    abort: abortCoreStreamInfoFetch,
    canAbort: canAbortCoreStreamInfoFetch,
  } = useGetCoreStreamInfo({ immediate: false }, streamShopName);

  const abortStreamInfoFetch = () => {
    if (canAbortCoreStreamInfoFetch.value) {
      abortCoreStreamInfoFetch();
    }
    if (canAbortVideeoStreamInfoFetch.value) {
      abortVideeoStreamInfoFetch();
    }
  };
  const streamInfoData = computed(() => {
    if (origin.value === 'videeo') {
      return videeoStreamInfoData.value;
    } else {
      return coreStreamInfoData.value;
    }
  });
  const getStreamInfo = async () => {
    resetStreamInfoPoll();
    if (origin.value === 'videeo') {
      return getVideeoStreamInfo(true);
    } else {
      return getCoreStreamInfo(true);
    }
  };
  RTMEvents.on('GetStreamInfo', () => {
    getStreamInfo();
  });
  const isShopLive = computed<boolean>(() => {
    if (streamInfoData.value?.is_streaming_now) {
      return true;
    }
    return false;
  });

  const isStrikethroughEnabled = computed<boolean>(() => {
    if (streamInfoData.value?.strikethrough_enabled) {
      return true;
    }
    return false;
  });

  const isVideeoForShopifyEnabled = computed<boolean>(() => {
    if (streamInfoData.value?.isVideeoForShopifyEnabled) {
      return true;
    }
    return false;
  });

  const localUserStreamStatus = computed<StreamStatus>(() => {
    if (hasJoinedChannel.value) {
      if (user.value.type === 'guest-producer' && localUser.value.onAir) {
        return 'live';
      } else if (user.value.type !== 'guest-producer' && isShopLive.value) {
        return 'live';
      }
      return 'streaming';
    }
    return 'offline';
  });

  const localUserIsLive = computed(
    () => localUserStreamStatus.value === 'live'
  );

  const viewerCount = computed<string>(() => {
    if (hasJoinedChannel.value && isShopLive.value && streamInfoData.value) {
      return viewerCountFormat.format(streamInfoData?.value?.user_count ?? 0);
    }
    return '--';
  });

  const liveSaleStartedTimestamp = computed<Date | null>(() => {
    if (streamInfoData.value?.secondsLive) {
      return new Date(Date.now() - 1000 * streamInfoData.value.secondsLive);
    }
    return null;
  });

  const localUserAddedToLayoutTimeStamp = ref<Date | null>(null);

  const streamTimeElapsedSeconds = ref<number>(0);
  const streamTimeElapsedText = computed<string>(() => {
    if (streamTimeElapsedSeconds.value > 0) {
      return formatSecondsToDuration(streamTimeElapsedSeconds.value);
    }
    return '--';
  });

  const streamCurrentProduct = computed(() => {
    return streamInfoData.value?.currentProduct[0];
  });

  const streamOverlayText = computed(() => streamInfoData.value?.overlay_text);
  watch(
    [user, localUser, isShopLive, liveSaleStartedTimestamp, hasJoinedChannel],
    () => {
      if (user.value.type === 'director') {
        return;
      }
      if (!isShopLive.value || !hasJoinedChannel.value) {
        streamTimeElapsedSeconds.value = 0;
        localUserAddedToLayoutTimeStamp.value = null;
      }
      if (user.value.type == 'guest-producer' && !localUser.value.onAir) {
        streamTimeElapsedSeconds.value = 0;
        localUserAddedToLayoutTimeStamp.value = null;
      }
      if (localUser.value.onAir && !localUserAddedToLayoutTimeStamp.value) {
        localUserAddedToLayoutTimeStamp.value = new Date();
      }
    }
  );
  const isFetchingStreamInfo = computed<boolean>(
    () => coreStreamInfoIsFetching.value || videeoStreamInfoIsFetching.value
  );
  const refreshStreamInfo = async () => {
    if (hasJoinedChannel.value && !isFetchingStreamInfo.value) {
      await getStreamInfo();
      streamInfoLoaded.value = true;
    }
  };

  const refreshStreamTimer = () => {
    if (user.value.type === 'director') {
      return;
    }
    if (localUserStreamStatus.value === 'live') {
      if (user.value.type === 'guest-producer') {
        if (localUserAddedToLayoutTimeStamp.value) {
          streamTimeElapsedSeconds.value = Math.round(
            (Date.now() - +localUserAddedToLayoutTimeStamp.value) / 1000
          );
        }
      } else if (liveSaleStartedTimestamp.value) {
        streamTimeElapsedSeconds.value = Math.round(
          (Date.now() - +liveSaleStartedTimestamp.value) / 1000
        );
      }
    }
  };

  let streamInfoPollID: ReturnType<typeof setInterval>;
  const pollStreamInfo = () => {
    streamInfoPollID = setInterval(
      refreshStreamInfo,
      streamInfoPollingInterval
    );
  };
  const resetStreamInfoPoll = () => {
    clearInterval(streamInfoPollID);
    pollStreamInfo();
  };
  resetStreamInfoPoll();
  setInterval(refreshStreamTimer, 1000);

  return {
    localUserStreamStatus,
    viewerCount,
    streamTimeElapsedText,
    isShopLive,
    isFetchingStreamInfo,
    streamCurrentProduct,
    streamInfoLoaded,
    refreshStreamInfo,
    isVideeoForShopifyEnabled,
    localUserIsLive,
    getStreamInfo,
    isStrikethroughEnabled,
    streamOverlayText,
    abortStreamInfoFetch,
  };
});
