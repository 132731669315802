import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Layout, type Origin } from '@/types';

let broadcastWebOrigin = '';
export const setBroadcastWebOrigin = (value: string) =>
  (broadcastWebOrigin = value);
export const setBroadcastWebOriginToCurrentOrigin = () =>
  (broadcastWebOrigin = window.location.origin);

export const useAppStore = defineStore('bwAppStore', () => {
  const origin = ref<Origin | ''>('');
  const showAddGuestToLiveDialog = ref<boolean>(false);
  const showChangeLayoutPreviewDialog = ref<boolean>(false);
  const suppressNetworkQualityWarnings = ref<boolean>(false);
  const muteAllVideoTiles = ref(false);
  const updateMuteAllVideoTiles = (value: boolean) => {
    muteAllVideoTiles.value = value;
  };
  const changeLayoutDialogPreselectedLayout = ref<keyof typeof Layout>(
    Layout.FullScreen_Host
  );
  const setChangeLayoutDialogPreselectedLayout = (
    newLayout: keyof typeof Layout
  ) => {
    changeLayoutDialogPreselectedLayout.value = newLayout;
  };
  const setOrigin = (value: Origin) => (origin.value = value);
  const broadcastWebUrl = computed<string>(() => {
    return `https://${broadcastWebOrigin}/${origin.value}`;
  });
  const multihostCoreToken = ref<string>('');
  const setMultihostCoreToken = (value: string) =>
    (multihostCoreToken.value = value);
  const showNavigationLoader = ref(false);
  const setSuppressNetworkQualityWarnings = (value: boolean) => {
    suppressNetworkQualityWarnings.value = value;
  };
  const shouldShowSettings = ref(false);
  const setShouldShowSettings = (value: boolean) => {
    shouldShowSettings.value = value;
  };
  const multihostSeviceIsIntialized = ref(false);
  return {
    origin,
    multihostSeviceIsIntialized,
    broadcastWebUrl,
    showAddGuestToLiveDialog,
    showChangeLayoutPreviewDialog,
    changeLayoutDialogPreselectedLayout,
    setChangeLayoutDialogPreselectedLayout,
    setOrigin,
    showNavigationLoader,
    multihostCoreToken,
    setMultihostCoreToken,
    suppressNetworkQualityWarnings,
    setSuppressNetworkQualityWarnings,
    shouldShowSettings,
    setShouldShowSettings,
    muteAllVideoTiles,
    updateMuteAllVideoTiles,
  };
});
