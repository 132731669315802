<script setup lang="ts">
import LoadingBar from '@/components/shared/LoadingBar.vue';
import { RouterView } from 'vue-router';
import { useAppStore } from '@/composables/useAppStore';
const appStore = useAppStore();
</script>

<template>
  <LoadingBar
    v-if="appStore.showNavigationLoader"
    class="tw-h-1 !tw-absolute tw-text-blue-500 tw-z-20"
  />
  <RouterView />
</template>

<style scoped></style>
