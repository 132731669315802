import { createFetch, useFetch } from '@vueuse/core';
import { computed, ref, type Ref } from 'vue';
import type { UseFetchReturn, UseFetchOptions } from '@vueuse/core';
import type {
  UserCredentials,
  User,
  StreamProduct,
  BroadcastSettingsResponse,
  ShopBroadcastSettings,
} from '@/types';
import { userStorageKey } from './storageKeys';
import { queryObjectToSearchParams } from '@/utils/query';

type FetchResponse<T> = UseFetchReturn<T> & PromiseLike<UseFetchReturn<T>>;
type CoreFetch<T> = (options?: UseFetchOptions) => FetchResponse<T>;

const getToken = () => {
  try {
    const { token }: UserCredentials = JSON.parse(
      localStorage.getItem(userStorageKey) ?? 'null'
    );
    return token;
  } catch (e) {
    return null;
  }
};

const coreBaseURL = ref('');
let shopBaseDomain = '';
export const setCoreBaseURL = (url: string | URL) => {
  url = url.toString();
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }
  coreBaseURL.value = url;
};
export const setShopBaseDomain = (domain: string) => {
  shopBaseDomain = domain;
};

export const useCoreApi = () => {
  const useCoreFetch = createFetch({
    baseUrl: coreBaseURL,
    options: {
      beforeFetch({ options }) {
        const token = getToken();
        if (token) {
          const headers = new Headers(options.headers);
          headers.set('Authorization', `Bearer ${token}`);
          options.headers = headers;
        }
        return { options };
      },
      onFetchError(context) {
        const { response } = context;
        if (response?.status === 401) {
          localStorage.removeItem(userStorageKey);
          window.location.reload();
        }
        return context;
      },
    },
    fetchOptions: {
      mode: 'cors',
      headers: {
        accept: 'application/json',
      },
    },
  });

  const useLogin = (email: Ref<string>, password: Ref<string>) => {
    const payload = computed(() => ({
      email: email.value,
      password: password.value,
    }));
    const apiUrl = computed(() => {
      return `${coreBaseURL.value}/api/multihost/v1/login`;
    });
    //using standalone fetch to avoid redirecting on 401
    const { isFetching, isFinished, data, execute, error } = useFetch<User>(
      apiUrl,
      {
        immediate: false,
        onFetchError(ctx) {
          if (ctx.data?.message) {
            ctx.error.cause = ctx.data.message;
            return ctx;
          }
          return ctx;
        },
      }
    )
      .post(payload)
      .json();

    return {
      login: execute,
      isLoggingIn: isFetching,
      isFinished,
      data,
      error,
    };
  };

  const useGetMiddlewareToken: CoreFetch<{ token: string }> = (options = {}) =>
    useCoreFetch('/api/multihost/v1/middleware-token', options).get().json();

  const useStartRTMP = (options = {}, channel: Ref<string>) => {
    const payload = computed(() => {
      return { agoraChannel: channel.value };
    });
    return useCoreFetch<{ rtmp_url: string }>('/api/multihost/v1/rtmp', options)
      .post(payload)
      .json();
  };
  type GetStreamInfo = (
    options: UseFetchOptions,
    shopName: Ref<string>
  ) => FetchResponse<{
    is_streaming_now: boolean;
    secondsLive: number;
    user_count: number;
    currentProduct: StreamProduct[];
    hasThirdAttributeEnabled?: boolean;
    isVideeoForShopifyEnabled?: boolean;
    strikethrough_enabled: boolean;
    overlay_text: string[];
  }>;

  type GetBroadcastSettings = (
    options: UseFetchOptions,
    flags: string[]
  ) => FetchResponse<BroadcastSettingsResponse>;

  type GetGuestBroadcastSettings = (
    options: UseFetchOptions,
    flags: string[],
    shopName: Ref<string>
  ) => FetchResponse<BroadcastSettingsResponse>;

  const useGetVideeoStreamInfo: GetStreamInfo = (
    options,
    shopName: Ref<string>
  ) => {
    const url = computed(() => `/videeo/v1/sdk/${shopName.value}/stream-info`);
    return useCoreFetch<{
      is_streaming_now: boolean;
      secondsLive: number;
      user_count: number;
      overlay_text: string[];
    }>(url, options)
      .get()
      .json();
  };

  const useGetBroadcastSettings: GetBroadcastSettings = (
    options: UseFetchOptions,
    flags: string[]
  ) => {
    return useCoreFetch<BroadcastSettingsResponse>(
      `/api/multihost/v1/settings?${queryObjectToSearchParams({
        flags,
      })}`,
      options
    )
      .get()
      .json();
  };

  const useGetGuestBroadcastSettings: GetGuestBroadcastSettings = (
    options: UseFetchOptions,
    flags: string[],
    shopName: Ref<string>
  ) => {
    const apiUrl = computed(() => {
      return `${coreBaseURL.value}/api/multihost/v1/${
        shopName.value
      }/settings?${queryObjectToSearchParams({
        flags,
      })}`;
    });
    return useFetch<BroadcastSettingsResponse>(apiUrl, options).get().json();
  };

  const useUpdateBroadcastSettings = (
    options: UseFetchOptions,
    payload: Ref<Partial<ShopBroadcastSettings>>
  ) => {
    return useCoreFetch<Omit<BroadcastSettingsResponse, 'flags'>>(
      `/api/multihost/v1/settings`,
      options
    )
      .post(payload)
      .json();
  };

  const useShopApi = createFetch({
    fetchOptions: {
      mode: 'cors',
      headers: {
        accept: 'application/json',
      },
    },
  });
  const useGetCoreStreamInfo: GetStreamInfo = (
    options,
    shopName: Ref<string>
  ) => {
    const url = computed(
      () => `https://${shopName.value}.${shopBaseDomain}/live/stream-info`
    );
    return useShopApi<{
      is_streaming_now: boolean;
      secondsLive: number;
      user_count: number;
      overlay_text: string[];
    }>(url, options)
      .get()
      .json();
  };

  return {
    useLogin,
    useStartRTMP,
    useGetBroadcastSettings,
    useGetGuestBroadcastSettings,
    useUpdateBroadcastSettings,
    useGetMiddlewareToken,
    useGetVideeoStreamInfo,
    useGetCoreStreamInfo,
  };
};

export const useSignedLogin = (
  signature: string,
  expires: string,
  user: string
) => {
  const url = new URL(
    `${coreBaseURL.value}/api/multihost/v1/signed-login?signature=${signature}&expires=${expires}&user=${user}`
  );
  return useFetch(url.toString(), {
    immediate: false,
  })
    .get()
    .json<{ data: User }>();
};
