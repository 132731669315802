export const getObject = <T>(name: string): T | null => {
  const tokenString = localStorage.getItem(name);
  if (tokenString) {
    try {
      return JSON.parse(tokenString);
    } catch (e) {
      console.error(e);
      console.error("Couldn't parse token string");
    }
  }
  return null;
};

export const setObject = (
  name: string,
  value: Record<string, string | number | boolean>
) => {
  localStorage.setItem(name, JSON.stringify(value));
};
