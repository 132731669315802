<template>
  <!-- Set the text color on this component to change its colors: <LoadingBar class="tw-text-blue-500 tw-h-1"/> -->
  <span class="loading-track" ref="loadingTrack">
    <span class="loading-bar" ref="loadingBar" v-bind="$attrs"></span>
  </span>
</template>

<style scoped>
.loading-track {
  width: 100%;
  display: inline-block;
  position: relative;
  background-color: rgba(255, 255, 255, 0.15);
  background-color: color-mix(in srgb, currentcolor, #fff 60%);
  overflow: hidden;
}
.loading-track .loading-bar {
  content: '';
  width: 192px;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background-color: currentColor;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%) scaleX(1);
  }
  100% {
    left: 130%;
    transform: translateX(0%) scaleX(4);
  }
}
</style>
