import { createApp } from 'vue';
import router from '@/app/router';
import * as Sentry from '@sentry/vue';
import { createPinia, storeToRefs } from 'pinia';

import App from './App.vue';

import { useMiddlewareStore } from '@/composables/useMiddlewareStore';

import './style.css';
import '@commentsold/multihost/css';
import { createMultihostService } from '@commentsold/multihost';
import { createMultihostComponents } from '@/lib/plugin';

const app = createApp(App);
if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.05,
    tracePropagationTargets: [],
  });
}

app.use(router);
app.use(createPinia());
app.use(
  createMultihostComponents({
    coreApiUrl: import.meta.env.VITE_API_DOMAIN_CORE,
    streamInfoPollingInterval: import.meta.env.VITE_STREAM_INFO_INTERVAL,
    optionalParams: {
      broadcastWebOrigin: import.meta.env.VITE_BROADCAST_WEB_ORIGIN,
      coreShopDomain: import.meta.env.VITE_CORE_SHOP_DOMAIN,
    },
  })
);
const { middlewareToken } = storeToRefs(useMiddlewareStore());

app.use(
  createMultihostService({
    middlewareToken: middlewareToken,
    middlewareURL: import.meta.env.VITE_API_DOMAIN_MIDDLEWARE,
    agoraAppId: import.meta.env.VITE_AGORA_APP_ID,
  })
);
app.mount('#app');
