export const formatSecondsToDuration = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = totalSeconds % 60;

  return [hours, minutes, seconds]
    .map((v) => `${v}`.padStart(2, '0'))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};
