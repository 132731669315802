import type { Plugin } from 'vue';
import { setStreamInfoPollingInterval } from '@/composables/useStreamStore';
import { setCoreBaseURL, setShopBaseDomain } from '@/composables/useCoreApi';
import { setBroadcastWebOrigin } from '@/composables/useAppStore';

type PluginOptions = {
  coreApiUrl: string;
  streamInfoPollingInterval?: number;
  optionalParams?: { coreShopDomain?: string; broadcastWebOrigin?: string };
};
export const createMultihostComponents = ({
  coreApiUrl,
  streamInfoPollingInterval,
  optionalParams,
}: PluginOptions): Plugin => {
  return {
    install() {
      if (!coreApiUrl) {
        throw new Error(
          '[@commentsold/multihost-components] No coreApiUrl provided to plugin'
        );
      }
      setCoreBaseURL(coreApiUrl);
      if (optionalParams?.coreShopDomain) {
        setShopBaseDomain(optionalParams.coreShopDomain);
      }
      if (optionalParams?.broadcastWebOrigin) {
        setBroadcastWebOrigin(optionalParams.broadcastWebOrigin);
      }
      if (streamInfoPollingInterval) {
        setStreamInfoPollingInterval(streamInfoPollingInterval);
      }
    },
  };
};
