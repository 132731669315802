export const queryObjectToSearchParams = (
  query: Record<string, string[] | number[] | string | number>,
  searchParams?: URLSearchParams
) => {
  searchParams = searchParams ?? new URLSearchParams();
  for (const key of Object.keys(query)) {
    if (Array.isArray(query[key])) {
      for (const item of query[key] as string[]) {
        searchParams.append(`${key}[]`, item);
      }
    } else {
      searchParams.append(key, query[key] as string);
    }
  }
  return searchParams.toString();
};
