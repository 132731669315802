import type { RumInitConfiguration } from '@datadog/browser-rum';

const importDatadogRum = () => import('@datadog/browser-rum');

export interface DatadogServiceConfig extends RumInitConfiguration {
  recordSession?: boolean;
  user?: DatadogServiceUser;
}

export interface DatadogServiceUser {
  id?: string;
  email?: string;
  name?: string;
  [key: string]: unknown;
}

let datadogRum:
  | Awaited<ReturnType<typeof importDatadogRum>>['datadogRum']
  | null = null;

export const initDatadog = async (config: DatadogServiceConfig | null) => {
  if (import.meta.env.DEV) {
    return;
  }
  try {
    const datadog = await importDatadogRum();
    datadogRum = datadog.datadogRum;
    if (!config) {
      throw new Error('Config is required to configure DataDog');
    }

    const {
      applicationId,
      clientToken,
      env = import.meta.env.MODE,
      recordSession = false,
      service = import.meta.env.VITE_DATADOG_SERVICE_NAME,
      version = '1.0.0-unspecified',
    } = config;
    if (!applicationId || !clientToken) {
      throw new Error(
        '`applicationId` and `clientToken` are required to configure DataDog'
      );
    }

    const datadogRumConfig: RumInitConfiguration = {
      applicationId,
      clientToken,
      env,
      // @NOTE DO NOT CHANGE THIS!!!
      site: 'datadoghq.com',
      service,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      defaultPrivacyLevel: 'mask-user-input',
    };

    datadogRum?.init(datadogRumConfig);

    if (recordSession) {
      datadogRum?.startSessionReplayRecording();
    }
  } catch (e) {
    console.error(e);
    console.warn('Failed to initialize datadog');
  }
};

export const setUser = (user: DatadogServiceUser): void => {
  datadogRum?.setUser(user);
};

export const unsetUser = (): void => {
  datadogRum?.clearUser();
};

export const logError = (
  context: string,
  error: Error,
  errorArgs: Record<string, string>
): void => {
  datadogRum?.addError(error, {
    errorArgs,
    context,
  });
};

export const logEvent = (
  context: string,
  args: Record<string, string>
): void => {
  datadogRum?.addAction(context, args);
};
