import { ShopBroadcastSettings } from '@/types';

export const defaultShopBroadcastSettings: ShopBroadcastSettings = {
  host: {
    videoBitrate: 2000,
    audioBitrate: 128,
  },
  guest: {
    defaultVideoBitrate: 2000,
    defaultAudioBitrate: 128,
  },
};
