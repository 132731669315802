import { computed, ref } from 'vue';
import { User } from '@/types';
import { defineStore } from 'pinia';
import { userStorageKey } from '@/composables/storageKeys';
import * as storage from '@/services/storage';
import { isJWTExpired } from '@/services/decodeJWT';
export const useUserStore = defineStore('mhcUserStore', () => {
  const user = ref<User>({
    id: '',
    email: '',
    name: '',
    shopName: '',
    token: '',
    type: 'guest-producer',
  });

  const getStoredUser = async () => {
    const user = storage.getObject<User>(userStorageKey);
    if (user && !isJWTExpired(user.token)) {
      setUser(user);
    }
  };

  const setUser = (newUser: Partial<User>) => {
    Object.assign(user.value, newUser);
    if (newUser.type !== 'guest-producer') {
      return storage.setObject(userStorageKey, user.value);
    }
  };
  const setToken = (token: string) => setUser({ token });
  const setShopName = (shopName: string) => setUser({ shopName });
  const isLoggedIn = computed(() => Boolean(user.value.token));

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };
  return {
    user,
    setUser,
    setToken,
    setShopName,
    getStoredUser,
    isLoggedIn,
    logOut,
  };
});
