import {
  createRouter,
  createWebHistory,
  RouteLocation,
  type RouteRecordRaw,
} from 'vue-router';

import routerConstants from './router.constants';
import BaseView from '@/app/views/BaseView.vue';
import { getIsRouteAuthenticated, handleAuthenticatedRoute } from './auth';
import { useAppStore } from '@/composables/useAppStore';
import { useUserStore } from '@/composables/useUserStore';
import { Origin } from '@/types';
import { initDatadog, setUser } from '@/services/datadog';

const authenticatedMeta = { authenticated: true };

export const routes: RouteRecordRaw[] = [
  {
    path: '/:origin',
    props: true,
    children: [
      {
        path: '',
        name: routerConstants.routes.BROADCAST_PARENT,
        component: BaseView,
        children: [
          {
            path: '',
            name: routerConstants.routes.BROADCAST.HOST,
            component: () => import('@/app/views/HostView.vue'),
            meta: authenticatedMeta,
          },
          {
            path: 'guest/:inviteId',
            name: routerConstants.routes.BROADCAST.GUEST,
            component: () => import('@/app/views/GuestView.vue'),
            props: true,
            meta: authenticatedMeta,
          },
          // {
          //   path: 'test',
          //   name: routerConstants.routes.BROADCAST.HOST,
          //   component: () => import('@/app/views/TestView.vue'),
          //   meta: { authenticated: false },
          // },
        ],
      },
      {
        path: 'director',
        name: routerConstants.routes.DIRECTOR,
        component: () => import('@/app/views/DirectorView.vue'),
        meta: authenticatedMeta,
      },
      {
        path: 'directorsetup',
        name: routerConstants.routes.DIRECTOR_SETUP,
        component: () => import('@/app/views/SetupView.vue'),
        meta: authenticatedMeta,
      },
      {
        path: 'login',
        name: routerConstants.routes.LOGIN,
        component: () => import('@/app/views/LoginView.vue'),
        props: true,
      },
      {
        path: 'inviteguest/:inviteId',
        name: routerConstants.routes.BROADCAST.INVITE_GUEST,
        component: () => import('@/app/views/InviteGuestView.vue'),
        props: true,
      },
      {
        path: 'invitehost/:loginToken?',
        name: routerConstants.routes.BROADCAST.INVITE_HOST,
        component: () => import('@/app/views/InviteHostView.vue'),
        props: true,
      },
      {
        path: 'error',
        props: true,
        name: routerConstants.routes.ERROR_PARENT,
        component: () => import('@/app/views/ErrorView.vue'),
        children: [
          {
            path: 'invalidinvite',
            name: routerConstants.routes.ERROR.INVALID_INVITE,
            component: () => import('@/app/views/InvalidInviteView.vue'),
          },
          {
            path: 'invitecheckerror',
            name: routerConstants.routes.ERROR.INVITE_CHECK_ERROR,
            component: () => import('@/app/views/InviteCheckError.vue'),
          },
          {
            path: 'accountnotenabled',
            name: routerConstants.routes.ERROR.FEATURE_FLAG_DISABLED,
            component: () => import('@/app/views/FeatureFlagDisabledView.vue'),
          },
        ],
      },
      {
        path: 'liveended',
        props: true,
        name: routerConstants.routes.LIVE_ENDED.PARENT,
        component: () => import('@/app/views/ErrorView.vue'),
        children: [
          {
            path: 'host',
            name: routerConstants.routes.LIVE_ENDED.HOST,
            component: () => import('@/app/views/LiveEndedHostView.vue'),
          },
          {
            path: 'guest',
            name: routerConstants.routes.LIVE_ENDED.GUEST,
            component: () => import('@/app/views/LiveEndedGuestView.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/commentsold',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(async (to): Promise<RouteLocation | false | undefined> => {
  useAppStore().showNavigationLoader = true;
  const { setOrigin } = useAppStore();
  if (to.params.origin) {
    setOrigin(to.params.origin as Origin);
  }
  const store = useUserStore();
  if (typeof to.query.token === 'string') {
    localStorage.removeItem('middleware-token');
    store.setToken(to.query.token);
  }
  const isAuthenticatedRoute = getIsRouteAuthenticated(to);
  if (isAuthenticatedRoute) {
    const route = await handleAuthenticatedRoute(to);
    initDatadog({
      applicationId: import.meta.env.VITE_DATADOG_APP_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      service: import.meta.env.VITE_DATADOG_SERVICE_NAME,
    })
      .then(() => {
        return setUser({
          email: store.user.email,
          name: store.user.shopName,
          id: store.user.id.toString(),
        });
      })
      .catch(console.error);

    return route as RouteLocation;
  }
});
router.afterEach(() => {
  useAppStore().showNavigationLoader = false;
});
export default router;
